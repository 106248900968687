import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/stone.svg'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "steps-carousel" }
const _hoisted_5 = { class: "carousel-wrapper" }
const _hoisted_6 = { class: "icon-wrapper" }
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "step-title" }
const _hoisted_10 = { class: "step-description" }
const _hoisted_11 = { class: "carousel-navigation" }
const _hoisted_12 = { class: "carousel-dots" }
const _hoisted_13 = { class: "carousel-buttons" }

import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useWindowSize } from "@vueuse/core"; // Для отслеживания ширины экрана

// Импортируем иконки
import starIcon from "@/assets/star.svg";
import card1Svg from "@/assets/card1Svg.svg";
import card2Svg from "@/assets/card2Svg.svg";
import card3Svg from "@/assets/card3Svg.svg";
import card4Svg from "@/assets/card4Svg.svg";
import card5Svg from "@/assets/card5Svg.svg";

// Индекс текущего слайда

export default /*@__PURE__*/_defineComponent({
  __name: 'AppSteps',
  setup(__props) {

const carouselIndex = ref(0);
const { t } = useI18n();

const steps = computed(() => [
  {
    icon: starIcon,
    innerIcon: card1Svg,
    title: t("steps.card1Title"),
    description: t("steps.card1Desc"),
  },
  {
    icon: starIcon,
    innerIcon: card2Svg,
    title: t("steps.card2Title"),
    description: t("steps.card2Desc"),
  },
  {
    icon: starIcon,
    innerIcon: card3Svg,
    title: t("steps.card3Title"),
    description: t("steps.card3Desc"),
  },
  {
    icon: starIcon,
    innerIcon: card4Svg,
    title: t("steps.card4Title"),
    description: t("steps.card4Desc"),
  },
  {
    icon: starIcon,
    innerIcon: card5Svg,
    title: t("steps.card5Title"),
    description: t("steps.card5Desc"),
  },
]);

// Функции навигации слайдера
function prevSlide() {
  carouselIndex.value =
    (carouselIndex.value - 1 + steps.value.length) % steps.value.length;
}

function nextSlide() {
  carouselIndex.value = (carouselIndex.value + 1) % steps.value.length;
}

// Отслеживание ширины экрана
const { width } = useWindowSize();
const isMobile = computed(() => width.value <= 700);

// Функция определения класса для слайда
function getSlideClass(index: number) {
  if (isMobile.value) {
    // Для мобильных устройств показываем только активный слайд
    return index === carouselIndex.value ? "active-slide" : "hidden-slide";
  } else {
    // Для десктопов показываем активный и следующий слайды
    if (index === carouselIndex.value) {
      return "active-slide";
    } else if (index === (carouselIndex.value + 1) % steps.value.length) {
      return "next-slide";
    } else {
      return "hidden-slide";
    }
  }
}

// Логика обработки свайпа
let touchStartX = 0;

function handleTouchStart(event: TouchEvent) {
  touchStartX = event.changedTouches[0].screenX;
}

function handleTouchEnd(event: TouchEvent) {
  const touchEndX = event.changedTouches[0].screenX;
  const deltaX = touchEndX - touchStartX;

  if (Math.abs(deltaX) > 50) {
    // Минимальная дистанция свайпа для срабатывания
    if (deltaX > 0) {
      // Свайп вправо
      prevSlide();
    } else {
      // Свайп влево
      nextSlide();
    }
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "section-title",
        innerHTML: _ctx.$t('steps.title')
      }, null, 8, _hoisted_3),
      _createVNode(_component_v_img, {
        src: _imports_0,
        alt: "Semicircle Icon",
        class: "semicircle-icon"
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value, (step, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["carousel-item", getSlideClass(index)]),
            key: index,
            onTouchstart: handleTouchStart,
            onTouchend: handleTouchEnd
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["step-card", { 'active-card': index === carouselIndex.value }])
            }, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("img", {
                  src: step.icon,
                  alt: "Step Icon",
                  class: "step-icon"
                }, null, 8, _hoisted_7),
                _createElementVNode("img", {
                  src: step.innerIcon,
                  alt: "Inner Icon",
                  class: "inner-icon"
                }, null, 8, _hoisted_8)
              ]),
              _createElementVNode("h2", _hoisted_9, _toDisplayString(step.title), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(step.description), 1)
            ], 2)
          ], 34))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(steps.value.length, (index) => {
          return (_openBlock(), _createElementBlock("span", {
            key: index,
            class: _normalizeClass({ active: index - 1 === carouselIndex.value })
          }, null, 2))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_v_btn, {
          icon: "",
          onClick: prevSlide
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("mdi-chevron-left")
              ])),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, {
          icon: "",
          onClick: nextSlide
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_icon, null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("mdi-chevron-right")
              ])),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "container",
  id: "services"
}
const _hoisted_2 = { class: "section-title" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "section-description" }
const _hoisted_5 = { class: "cards-container" }
const _hoisted_6 = ["onMouseenter", "onMouseleave", "onClick"]
const _hoisted_7 = { class: "card-front" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "card-title" }
const _hoisted_10 = { class: "card-description" }
const _hoisted_11 = { class: "card-back-content" }
const _hoisted_12 = ["src", "alt"]
const _hoisted_13 = { class: "card-back-title" }
const _hoisted_14 = { class: "card-description" }

import api from "@/plugins/api";
import { ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";

// Состояние для наведения

export default /*@__PURE__*/_defineComponent({
  __name: 'AppServices',
  setup(__props) {

const hoverIndex = ref<number | null>(null);

// Состояние для перевёрнутых карточек
const flipped = ref<Set<number>>(new Set());

// Состояние для карточек
const cards = ref<Array<any>>([]);

// i18n
const { locale } = useI18n();

// Функция для получения полного URL иконки
function getIconUrl(iconPath: string) {
  if (iconPath.startsWith("http")) {
    return iconPath;
  }
  const baseUrl = process.env.VUE_APP_BASE_URL || "https://iwex-germany.de/";
  return `${baseUrl}${iconPath}`;
}

// Функция для загрузки карточек
async function loadCards(language: string) {
  try {
    const response = await api.get("/api/v1/cards/", {
      params: {
        lng: language,
      },
    });
    cards.value = response.data;
  } catch (error) {
    console.error("Ошибка при загрузке данных:", error);
  }
}

// Получаем данные с API при монтировании компонента
onMounted(() => {
  loadCards(locale.value);
});

// Обновляем данные при смене языка
watch(locale, (newLocale) => {
  loadCards(newLocale);
});

// Функция для переключения переворота карточки по клику
function toggleFlip(index: number) {
  if (flipped.value.has(index)) {
    flipped.value.delete(index);
  } else {
    flipped.value.add(index);
  }
  // Обновляем реактивность
  flipped.value = new Set(flipped.value);
}

// Функции для обработки наведения
function handleMouseEnter(index: number) {
  // Только для устройств с поддержкой наведения
  if (window.matchMedia("(hover: hover) and (pointer: fine)").matches) {
    hoverIndex.value = index;
  }
}

function handleMouseLeave(index: number) {
  // Только для устройств с поддержкой наведения
  if (window.matchMedia("(hover: hover) and (pointer: fine)").matches) {
    hoverIndex.value = null;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        innerHTML: _ctx.$t('services.title')
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("services.description")), 1),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards.value, (card, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "service-card",
          key: card.id
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["card-inner", {
            'is-flipped': hoverIndex.value === index || flipped.value.has(index),
          }]),
            onMouseenter: ($event: any) => (handleMouseEnter(index)),
            onMouseleave: ($event: any) => (handleMouseLeave(index)),
            onClick: ($event: any) => (toggleFlip(index))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                src: getIconUrl(card.icon),
                alt: card.title,
                class: "card-icon"
              }, null, 8, _hoisted_8),
              _createElementVNode("h2", _hoisted_9, _toDisplayString(card.title), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(card.text), 1)
            ]),
            _createElementVNode("div", {
              class: "card-back",
              style: _normalizeStyle({ backgroundImage: `url(${getIconUrl(card.thumbnail)})` })
            }, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("img", {
                  src: getIconUrl(card.icon),
                  alt: card.title,
                  class: "card-icon"
                }, null, 8, _hoisted_12),
                _createElementVNode("h2", _hoisted_13, _toDisplayString(card.title), 1),
                _createElementVNode("p", _hoisted_14, _toDisplayString(card.additionalText), 1)
              ])
            ], 4)
          ], 42, _hoisted_6)
        ]))
      }), 128))
    ])
  ]))
}
}

})
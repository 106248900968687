import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "nav-links" }
const _hoisted_3 = { class: "right-items" }
const _hoisted_4 = { class: "language-content" }
const _hoisted_5 = { class: "language-name" }

import { ref, watch, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import germanyFlag from "@/assets/germany-flag.png";
import usaFlag from "@/assets/usa-flag.png";
import logoImage from "@/assets/logo.png";

// Маршрутизатор

export default /*@__PURE__*/_defineComponent({
  __name: 'AppNavbar',
  setup(__props) {

const router = useRouter();

// i18n
const { locale, t } = useI18n();

// Состояние навигационного ящика
const drawer = ref(false);

// Состояние меню языка
const menuOpen = ref(false);

// Для мобильных устройств, включаем "Contact Us"
const mobileNavItems = [
  {
    labelKey: "navigation.services",
    path: "#services",
    sectionId: "services",
  },
  {
    labelKey: "navigation.aboutUs",
    path: "#about-us",
    sectionId: "about-us",
  },
  {
    labelKey: "navigation.contactUs",
    path: "/contact",
    isButton: true, // Добавлено свойство
  },
];

// Настройки языка
const languages = [
  {
    code: "deu",
    name: "Deutsch",
    flag: germanyFlag,
  },
  {
    code: "eng",
    name: "English",
    flag: usaFlag,
  },
];

// Текущий язык
const currentLanguage = ref(
  languages.find((lang) => lang.code === locale.value) || languages[0]
);

// Установка локали из localStorage при загрузке компонента
onMounted(() => {
  const savedLocale = localStorage.getItem("locale");
  if (savedLocale) {
    locale.value = savedLocale;
    currentLanguage.value =
      languages.find((lang) => lang.code === savedLocale) || languages[0];
  }
});

// Отслеживание изменения локали
watch(locale, (newLocale) => {
  currentLanguage.value =
    languages.find((lang) => lang.code === newLocale) || languages[0];
});

// Функция смены языка
function changeLanguage(lang: any) {
  locale.value = lang.code;
  localStorage.setItem("locale", lang.code);
}

function scrollToSection(sectionId: string) {
  if (router.currentRoute.value.path !== "/") {
    router.push("/").then(() => {
      nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    });
  } else {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
}

function handleMobileNavigation(path: any, sectionId?: string) {
  drawer.value = false; // Закрываем бургер-меню
  if (sectionId) {
    if (router.currentRoute.value.path !== "/") {
      router.push("/").then(() => {
        nextTick(() => {
          scrollToSection(sectionId);
        });
      });
    } else {
      scrollToSection(sectionId);
    }
  } else {
    router.push(path).then(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Плавная прокрутка
      });
    });
  }
}

function navigateToHome() {
  router.push("/").then(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Плавная прокрутка
    });
  });
}

function navigateToContact() {
  try {
    router.push("/contact");
  } catch (error) {
    console.error("Ошибка при переходе на страницу контактов:", error);
  }
}

return (_ctx: any,_cache: any) => {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_app_bar, {
      app: "",
      fixed: "",
      color: "#333333",
      dark: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_container, {
          fluid: "",
          class: "navbar-container"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, {
              align: "center",
              justify: "space-between",
              "no-gutters": ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "logo",
                      onClick: navigateToHome
                    }, [
                      _createElementVNode("img", {
                        src: _unref(logoImage),
                        alt: "Logo",
                        class: "logo-img"
                      }, null, 8, _hoisted_1)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, {
                  class: "d-none d-sm-flex",
                  cols: "auto"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_v_btn, {
                        variant: "text",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (scrollToSection('services'))),
                        class: "nav-link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)("navigation.services")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        variant: "text",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (scrollToSection('about-us'))),
                        class: "nav-link"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)("navigation.aboutUs")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_btn, {
                        onClick: navigateToContact,
                        class: "nav-button"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)("navigation.contactUs")), 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { cols: "auto" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_v_menu, {
                        "offset-y": "",
                        modelValue: menuOpen.value,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((menuOpen).value = $event))
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode(_component_v_btn, _mergeProps(props, { class: "language-btn" }), {
                            default: _withCtx(() => [
                              _createVNode(_component_v_avatar, { size: "24" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    src: currentLanguage.value?.flag
                                  }, null, 8, ["src"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_v_icon, { class: "ml-2" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(menuOpen.value ? "mdi-chevron-up" : "mdi-chevron-down"), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1040)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(languages, (lang, index) => {
                                return _createVNode(_component_v_list_item, {
                                  key: index,
                                  onClick: ($event: any) => (changeLanguage(lang)),
                                  class: "language-item"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_4, [
                                      _createVNode(_component_v_img, {
                                        src: lang.flag,
                                        class: "language-flag"
                                      }, null, 8, ["src"]),
                                      _createElementVNode("span", _hoisted_5, _toDisplayString(lang.name), 1)
                                    ])
                                  ]),
                                  _: 2
                                }, 1032, ["onClick"])
                              }), 64))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"]),
                      _createVNode(_component_v_app_bar_nav_icon, {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (drawer.value = !drawer.value)),
                        class: "d-sm-none"
                      })
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_v_navigation_drawer, {
      modelValue: drawer.value,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((drawer).value = $event)),
      app: "",
      temporary: "",
      class: "d-sm-none"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_list, { dense: "" }, {
          default: _withCtx(() => [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(mobileNavItems, (item, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                (!item.isButton)
                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 0,
                      onClick: ($event: any) => (handleMobileNavigation(item.path, item.sectionId))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)(item.labelKey)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (_openBlock(), _createBlock(_component_v_list_item, {
                      key: 1,
                      onClick: ($event: any) => (handleMobileNavigation(item.path, item.sectionId)),
                      class: "contact-list-item"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          class: "nav-button",
                          block: ""
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_unref(t)(item.labelKey)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
              ], 64))
            }), 64))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}
}

})
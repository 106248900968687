<template>
  <v-app-bar app fixed color="#333333" dark>
    <!-- Обёртка контента Navbar -->
    <v-container fluid class="navbar-container">
      <v-row align="center" justify="space-between" no-gutters>
        <!-- Логотип -->
        <v-col cols="auto">
          <div class="logo" @click="navigateToHome">
            <img :src="logoImage" alt="Logo" class="logo-img" />
          </div>
        </v-col>

        <!-- Навигационные ссылки для планшетов и десктопов -->
        <v-col class="d-none d-sm-flex" cols="auto">
          <div class="nav-links">
            <v-btn
              variant="text"
              @click="scrollToSection('services')"
              class="nav-link"
            >
              {{ t("navigation.services") }}
            </v-btn>
            <v-btn
              variant="text"
              @click="scrollToSection('about-us')"
              class="nav-link"
            >
              {{ t("navigation.aboutUs") }}
            </v-btn>
            <!-- Кнопка "Kontaktieren Sie uns" -->
            <v-btn @click="navigateToContact" class="nav-button">
              {{ t("navigation.contactUs") }}
            </v-btn>
          </div>
        </v-col>

        <!-- Правые элементы: Переключатель языка и бургер-меню -->
        <v-col cols="auto">
          <div class="right-items">
            <!-- Переключатель языка -->
            <v-menu offset-y v-model="menuOpen">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" class="language-btn">
                  <v-avatar size="24">
                    <v-img :src="currentLanguage?.flag"></v-img>
                  </v-avatar>
                  <v-icon class="ml-2">
                    {{ menuOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(lang, index) in languages"
                  :key="index"
                  @click="changeLanguage(lang)"
                  class="language-item"
                >
                  <div class="language-content">
                    <v-img :src="lang.flag" class="language-flag"></v-img>
                    <span class="language-name">{{ lang.name }}</span>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- Бургер-меню для мобильных устройств -->
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              class="d-sm-none"
            ></v-app-bar-nav-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>

  <!-- Навигационный ящик для мобильных устройств -->
  <v-navigation-drawer v-model="drawer" app temporary class="d-sm-none">
    <v-list dense>
      <!-- Навигационные ссылки -->
      <template v-for="(item, index) in mobileNavItems" :key="index">
        <v-list-item
          v-if="!item.isButton"
          @click="handleMobileNavigation(item.path, item.sectionId)"
        >
          <v-list-item-title>{{ t(item.labelKey) }}</v-list-item-title>
        </v-list-item>
        <v-list-item
          v-else
          @click="handleMobileNavigation(item.path, item.sectionId)"
          class="contact-list-item"
        >
          <v-btn class="nav-button" block>
            {{ t(item.labelKey) }}
          </v-btn>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import germanyFlag from "@/assets/germany-flag.png";
import usaFlag from "@/assets/usa-flag.png";
import logoImage from "@/assets/logo.png";

// Маршрутизатор
const router = useRouter();

// i18n
const { locale, t } = useI18n();

// Состояние навигационного ящика
const drawer = ref(false);

// Состояние меню языка
const menuOpen = ref(false);

// Для мобильных устройств, включаем "Contact Us"
const mobileNavItems = [
  {
    labelKey: "navigation.services",
    path: "#services",
    sectionId: "services",
  },
  {
    labelKey: "navigation.aboutUs",
    path: "#about-us",
    sectionId: "about-us",
  },
  {
    labelKey: "navigation.contactUs",
    path: "/contact",
    isButton: true, // Добавлено свойство
  },
];

// Настройки языка
const languages = [
  {
    code: "deu",
    name: "Deutsch",
    flag: germanyFlag,
  },
  {
    code: "eng",
    name: "English",
    flag: usaFlag,
  },
];

// Текущий язык
const currentLanguage = ref(
  languages.find((lang) => lang.code === locale.value) || languages[0]
);

// Установка локали из localStorage при загрузке компонента
onMounted(() => {
  const savedLocale = localStorage.getItem("locale");
  if (savedLocale) {
    locale.value = savedLocale;
    currentLanguage.value =
      languages.find((lang) => lang.code === savedLocale) || languages[0];
  }
});

// Отслеживание изменения локали
watch(locale, (newLocale) => {
  currentLanguage.value =
    languages.find((lang) => lang.code === newLocale) || languages[0];
});

// Функция смены языка
function changeLanguage(lang: any) {
  locale.value = lang.code;
  localStorage.setItem("locale", lang.code);
}

function scrollToSection(sectionId: string) {
  if (router.currentRoute.value.path !== "/") {
    router.push("/").then(() => {
      nextTick(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      });
    });
  } else {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }
}

function handleMobileNavigation(path: any, sectionId?: string) {
  drawer.value = false; // Закрываем бургер-меню
  if (sectionId) {
    if (router.currentRoute.value.path !== "/") {
      router.push("/").then(() => {
        nextTick(() => {
          scrollToSection(sectionId);
        });
      });
    } else {
      scrollToSection(sectionId);
    }
  } else {
    router.push(path).then(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Плавная прокрутка
      });
    });
  }
}

function navigateToHome() {
  router.push("/").then(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Плавная прокрутка
    });
  });
}

function navigateToContact() {
  try {
    router.push("/contact");
  } catch (error) {
    console.error("Ошибка при переходе на страницу контактов:", error);
  }
}
</script>

<style scoped lang="scss">
.navbar-container {
  max-width: 1440px;
  margin: 0 auto;
}

.logo {
  cursor: pointer;

  img {
    max-height: 40px;
    max-width: 150px;
  }
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  color: #afafaf;
  margin: 0 8px;
  text-transform: none;
}

.nav-link:hover {
  color: #ffffff !important;
}

.nav-button {
  margin-left: 16px;
  background-color: #feca00;
  color: #383838;
  text-transform: none;
}

.right-items {
  display: flex;
  align-items: center;
}

.language-btn {
  margin-left: 16px;
  display: flex;
  align-items: center;
  text-transform: none;
}

.language-item {
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.language-content {
  display: flex;
  align-items: center;
}

.language-flag {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.language-name {
  color: #383838;
}

.v-list-item {
  padding: 8px 16px;
}

.v-list-item__content {
  display: flex;
  align-items: center;
}

.v-list-item-title {
  margin-left: 8px;
  color: #383838;
}

.v-icon {
  font-size: 24px;
}

/* Адаптивные стили */
@media (max-width: 600px) {
  .language-btn,
  .nav-button {
    margin-left: 8px;
  }

  .logo img {
    max-height: 30px;
    max-width: 120px;
  }
  .nav-button {
    background-color: #feca00;
    color: #383838;
    text-transform: none;
    width: 100%;
  }
}
</style>

import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "cookie-content" }
const _hoisted_2 = { class: "cookie-title" }
const _hoisted_3 = { class: "cookie-message" }
const _hoisted_4 = { class: "cookie-message" }
const _hoisted_5 = { class: "button-group" }

import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'CookieConsent',
  setup(__props) {

const { t } = useI18n();

const showBanner = ref(false);

onMounted(() => {
  const consent = localStorage.getItem("cookieConsent");
  if (!consent) {
    showBanner.value = true;
  }
});

function acceptAll() {
  localStorage.setItem("cookieConsent", "accepted");
  showBanner.value = false;
}

function rejectAll() {
  localStorage.setItem("cookieConsent", "rejected");
  showBanner.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: showBanner.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((showBanner).value = $event)),
    color: "rgba(255, 203, 0, 1)",
    elevation: "0",
    bottom: "",
    timeout: -1,
    persistent: "",
    "max-width": "100%"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h2", _hoisted_2, _toDisplayString(_unref(t)("cookieConsent.title")), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)("cookieConsent.message")), 1),
        _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)("cookieConsent.message2")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_v_btn, {
            class: "cookie-button",
            onClick: rejectAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)("cookieConsent.reject")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            class: "cookie-button",
            onClick: acceptAll
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_unref(t)("cookieConsent.accept")), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})
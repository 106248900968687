import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "section-header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "circle-text" }
const _hoisted_5 = { class: "two" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "industries" }
const _hoisted_8 = ["src", "alt"]
const _hoisted_9 = { class: "industry-name" }
const _hoisted_10 = { class: "gallery-section" }
const _hoisted_11 = { class: "gallery-images" }
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "slider-button" }

import api from "@/plugins/api";
import { onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import mainImage from "@/assets/photo1.png";
import sideImage1 from "@/assets/photo2.png";
import sideImage2 from "@/assets/photo3.png";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppIndustry',
  setup(__props) {

const { locale } = useI18n();
const industries = ref<Array<any>>([]);
const router = useRouter();

// Функция для получения полного URL иконки
function getIconUrl(iconPath: string) {
  if (iconPath.startsWith("http")) {
    return iconPath;
  }
  const baseUrl = process.env.VUE_APP_BASE_URL || "https://iwex-germany.de/";
  return `${baseUrl}${iconPath}`;
}

// Функция для загрузки индустрий
async function loadIndustries(language: string) {
  try {
    const response = await api.get("/api/v1/workareas/", {
      params: {
        lng: language,
      },
    });
    industries.value = response.data;
  } catch (error) {
    console.error("Ошибка при загрузке данных индустрий:", error);
  }
}

// Обработчик клика по кнопке
function navigateToContact() {
  router.push("/contact"); // Переход на страницу контактов
}

// Загрузка данных при монтировании компонента
onMounted(() => {
  loadIndustries(locale.value);
});

// Обновление данных при смене языка
watch(locale, (newLocale) => {
  loadIndustries(newLocale);
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "section-title",
        innerHTML: _ctx.$t('industry.title')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("p", null, "200", -1)),
          _createElementVNode("span", {
            innerHTML: _ctx.$t('industry.circle')
          }, null, 8, _hoisted_6)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(industries.value, (industry) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "industry-item",
          key: industry.id
        }, [
          _createElementVNode("img", {
            src: getIconUrl(industry.icon),
            alt: industry.name,
            class: "industry-icon"
          }, null, 8, _hoisted_8),
          _createElementVNode("p", _hoisted_9, _toDisplayString(industry.name), 1)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("img", {
          src: _unref(mainImage),
          alt: "Main Image",
          class: "gallery-image main-image"
        }, null, 8, _hoisted_12),
        _createElementVNode("img", {
          src: _unref(sideImage1),
          alt: "Side Image 1",
          class: "gallery-image side-image"
        }, null, 8, _hoisted_13),
        _createElementVNode("img", {
          src: _unref(sideImage2),
          alt: "Side Image 2",
          class: "gallery-image side-image"
        }, null, 8, _hoisted_14)
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createVNode(_component_v_btn, {
        color: "rgba(254, 202, 0, 1)",
        onClick: navigateToContact,
        class: "btn-center"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("button")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})
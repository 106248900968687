import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ad-banner" }
const _hoisted_2 = { class: "overlay" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "headline" }
const _hoisted_5 = { class: "highlight" }
const _hoisted_6 = ["innerHTML"]

import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'AdBanner',
  setup(__props) {

const router = useRouter();

function navigateToContact() {
  router.push("/contact"); // Переход на страницу контактов
}

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.$t("banner.title.part1")) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("banner.title.highlight")), 1),
            _createTextVNode(" " + _toDisplayString(_ctx.$t("banner.title.part2")), 1)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("banner.title.part3")), 1)
        ]),
        _createElementVNode("p", {
          class: "subheadline",
          innerHTML: _ctx.$t('banner.description')
        }, null, 8, _hoisted_6),
        _createVNode(_component_v_btn, {
          class: "cta-button",
          onClick: navigateToContact
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("button")), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "company-info",
  id: "about-us"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "text-section" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "signature" }
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "image-section" }
const _hoisted_9 = { class: "image-wrapper" }
const _hoisted_10 = ["src"]

import signatureImage from "@/assets/signature.png";
import founderImage from "@/assets/container.png";

export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyInfo',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("company.title")), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("company.description")), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("img", {
            src: _unref(signatureImage),
            alt: "Signature",
            class: "signature-image"
          }, null, 8, _hoisted_7),
          _cache[0] || (_cache[0] = _createElementVNode("p", { class: "founder" }, [
            _createElementVNode("strong", null, "Nurdin Sheishenov"),
            _createElementVNode("br"),
            _createTextVNode("CEO")
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("img", {
            src: _unref(founderImage),
            alt: "Founder",
            class: "founder-image"
          }, null, 8, _hoisted_10)
        ])
      ])
    ])
  ]))
}
}

})